import React from 'react';
import {
  Improved,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="November 2021"
      subnav="release-notes">
      <div id="November2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          October and November were slow months for us in terms of releasing
          new features and bug fixes. In the background, we have been working
          tirelessly on new components for the Unified Product (Beta)
          experience. Be on the lookout for big updates next month! For now,
          enjoy the minor improvements we made in November.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.12.5 - 4.12.6"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Improved>
                Typescript support by exporting all Uniform component types.{' '}
                <code>ButtonProps</code>, for example.
              </Improved>
              <Fixed>
                <code>Dismiss</code> button color for{' '}
                <Link href="/components/dialogs/overlay/code">Overlay</Link>.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
